<template>
  <main class="glo">
    <div class="glo-banner">
      <div class="desk" style="height: 100%;">
        <div class="container ">
          <div class="glo-banner__info">
            <div class="glo-banner__info-logo">
              <img
                src="/glo/logoGlo.svg"
                alt=""
              />
            </div>
            <p>{{ $t("glo.banner.text") }}</p>
          </div>
          <div class="glo-banner__title">
            <h2>{{ $t("glo.banner.title") }}</h2>
            <h3>{{ $t("glo.banner.subtitle") }}</h3>
            <div class="glo-banner__title-img">
              <img
                src="/glo/logoPro.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mob" style="height: 100%;">
        <div class="container">
          <div class="glo-banner__info-logo">
            <img src="/glo/logoGlo.svg" alt="" />
          </div>
          <div class="glo-banner__info">
            <div class="glo-banner__title">
              <h2>Горячее предложение glo™</h2>
              <h3>3000 бонусов за покупку glo™</h3>
              <div class="glo-banner__title-img">
                <img src="/glo/logoPro.png" />
              </div>
              <p>
                * Данное устройство предназначено для использования с нагреваемыми табачными палочками,
                которые могут нанести вред вашему здоровью и вызывают привыкание. Информация и
                изображения, отображаемые на настоящем сайте, предназначены исключительно для
                зарегистрированных потребителей никотиносодержащих изделий ТОО «Magnum Cash&Carry»,
                достигших 21 года
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="glo-img">
      <div class="container">
        <h2>{{ $t("glo.img.title") }}</h2>
        <div class="glo-img__info">
          <div class="glo-img__info-col desk">
            <div class="item">
              <div class="line line-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="567" height="199" viewBox="0 0 567 199" fill="none">
                  <path d="M0 1H369L566.5 198.5" stroke="white"/>
                </svg>
              </div>
              <h3>{{ $t("glo.img.item1.title") }}</h3>
              <p>{{ $t("glo.img.item1.text") }}</p>
            </div>
            <div class="item">
              <div class="line line-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="398" height="47" viewBox="0 0 398 47" fill="none">
                  <path d="M0 1H352L397.5 46.5" stroke="white"/>
                </svg>
              </div>
              <h3>{{ $t("glo.img.item2.title") }}</h3>
              <p>{{ $t("glo.img.item2.text") }}</p>
            </div>
          </div>
          <div class="glo-img__info-img">
            <img src="/glo/img.png" alt="">
            <div class="mob">
              <div class="item item1">
                <div class="icon icon1" @click="isImg === 1 ? isImg = null : isImg = 1">
                  <svg v-if="isImg === 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <circle opacity="0.9" cx="12" cy="12" r="12" fill="#EFEFEF"/>
                    <circle cx="12" cy="12" r="11.6" fill="white" stroke="white" stroke-width="0.8"/>
                    <path d="M8.66406 11.8311H15.0569V13.3439H8.66406V11.8311Z" fill="black"/>
                  </svg>
                  <svg v-else  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <circle opacity="0.9" cx="12" cy="12" r="12" fill="#EFEFEF"/>
                    <circle cx="12" cy="12" r="11.6" fill="white" stroke="white" stroke-width="0.8"/>
                    <path d="M17.1218 12.9917H12.5834V17.4325H11.0462V12.9917H6.50781V11.5521H11.0462V7.08691H12.5834V11.5521H17.1218V12.9917Z" fill="black"/>
                  </svg>
                </div>
                <div v-if="isImg === 1" class="item-info">
                  <div class="line">
                    <svg xmlns="http://www.w3.org/2000/svg" width="249" height="47" viewBox="0 0 249 47" fill="none">
                      <path d="M248.5 46L140 1H0.5" stroke="white"/>
                    </svg>
                  </div>
              <h3>{{ $t("glo.img.item3.title") }}</h3>
              <p>{{ $t("glo.img.item3.text") }}</p>
                </div>
              </div>
              <div class="item item2">
                <div class="icon icon2" @click="isImg === 2 ? isImg = null : isImg = 2">
                  <svg v-if="isImg === 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <circle opacity="0.9" cx="12" cy="12" r="12" fill="#EFEFEF"/>
                    <circle cx="12" cy="12" r="11.6" fill="white" stroke="white" stroke-width="0.8"/>
                    <path d="M8.66406 11.8311H15.0569V13.3439H8.66406V11.8311Z" fill="black"/>
                  </svg>
                  <svg v-else  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <circle opacity="0.9" cx="12" cy="12" r="12" fill="#EFEFEF"/>
                    <circle cx="12" cy="12" r="11.6" fill="white" stroke="white" stroke-width="0.8"/>
                    <path d="M17.1218 12.9917H12.5834V17.4325H11.0462V12.9917H6.50781V11.5521H11.0462V7.08691H12.5834V11.5521H17.1218V12.9917Z" fill="black"/>
                  </svg>
                </div>
                <div v-if="isImg === 2" class="item-info">
                  <div class="line">
                    <svg xmlns="http://www.w3.org/2000/svg" width="244" height="5" viewBox="0 0 244 5" fill="none">
                      <path d="M243 4.5L233.5 1H0" stroke="white"/>
                    </svg>
                  </div>
                  <h3>{{ $t("glo.img.item4.title") }}</h3>
                  <p>{{ $t("glo.img.item4.text") }}</p>
                </div>
              </div>
              <div class="item item3" @click="isImg === 3 ? isImg = null : isImg = 3">
                <div class="icon icon3">
                  <svg v-if="isImg === 3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <circle opacity="0.9" cx="12" cy="12" r="12" fill="#EFEFEF"/>
                    <circle cx="12" cy="12" r="11.6" fill="white" stroke="white" stroke-width="0.8"/>
                    <path d="M8.66406 11.8311H15.0569V13.3439H8.66406V11.8311Z" fill="black"/>
                  </svg>
                  <svg v-else  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <circle opacity="0.9" cx="12" cy="12" r="12" fill="#EFEFEF"/>
                    <circle cx="12" cy="12" r="11.6" fill="white" stroke="white" stroke-width="0.8"/>
                    <path d="M17.1218 12.9917H12.5834V17.4325H11.0462V12.9917H6.50781V11.5521H11.0462V7.08691H12.5834V11.5521H17.1218V12.9917Z" fill="black"/>
                  </svg>
                </div>
                <div  v-if="isImg === 3" class="item-info">
                  <h3>{{ $t("glo.img.item1.title") }}</h3>
                  <p>{{ $t("glo.img.item1.text") }}</p>
                  <div class="line">
                    <svg xmlns="http://www.w3.org/2000/svg" width="178" height="32" viewBox="0 0 178 32" fill="none">
                      <path d="M171 31L176.5 1L0 1" stroke="white"/>
                    </svg>
                  </div>
                </div>
              </div> 
              <div class="item item4" @click="isImg === 4 ? isImg = null : isImg = 4">
                <div class="icon icon4">
                  <svg v-if="isImg === 4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <circle opacity="0.9" cx="12" cy="12" r="12" fill="#EFEFEF"/>
                    <circle cx="12" cy="12" r="11.6" fill="white" stroke="white" stroke-width="0.8"/>
                    <path d="M8.66406 11.8311H15.0569V13.3439H8.66406V11.8311Z" fill="black"/>
                  </svg>
                  <svg v-else  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <circle opacity="0.9" cx="12" cy="12" r="12" fill="#EFEFEF"/>
                    <circle cx="12" cy="12" r="11.6" fill="white" stroke="white" stroke-width="0.8"/>
                    <path d="M17.1218 12.9917H12.5834V17.4325H11.0462V12.9917H6.50781V11.5521H11.0462V7.08691H12.5834V11.5521H17.1218V12.9917Z" fill="black"/>
                  </svg>
                </div>
                <div  v-if="isImg === 4" class="item-info">
                  <div class="line">
                    <svg xmlns="http://www.w3.org/2000/svg" width="212" height="47" viewBox="0 0 212 47" fill="none">
                      <path d="M1 1L116.5 46H212" stroke="white"/>
                    </svg>
                  </div>
                  <h3>{{ $t("glo.img.item2.title") }}</h3>
                  <p>{{ $t("glo.img.item2.text") }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="glo-img__info-col desk">
            <div class="item">
              <div class="line line-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="482" height="2" viewBox="0 0 482 2" fill="none">
                  <path d="M482 1.00004L0 1" stroke="white"/>
                </svg>
              </div>
              <h3>Технология HeatBoost™</h3>
              <p>Новая технология HeatBoost™ для усиленного вкуса при постоянном нагреве.</p>
            </div>
            <div class="item">
              <div class="line line-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="473" height="83" viewBox="0 0 473 83" fill="none">
                  <path d="M473 82H82L0.5 0.5" stroke="white"/>
                </svg>
              </div>
              <h3>TasteSelect™</h3>
              <p>Вы можете прокрутить колесо переключения режима для выбора режима, после чего начнется сессия. Если вы хотите продленный и размеренный сеанс длительностью 4,5 минуты, то вам подойдет Стандартный режим. А если отдаете предпочтение короткому сеансу с большей интенсивностью длительностью 3 минуты, то вы можете выбрать ускоренный Boost режим.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="glo-pre">
      <div class="container">
        <div class="glo-pre__infos">
          <div class="glo-pre__info">
            <div>
              <h2>{{ $t("glo.pre.title") }}</h2>
              <div class="glo-pre__items">
                <div  :key="i" class="glo-pre__item">
                  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
                    <path d="M13.5 24L24 34.5L40 18.5" stroke="white" stroke-width="3" stroke-linecap="round"/>
                    <circle cx="26" cy="26" r="24.5" stroke="white" stroke-width="3"/>
                  </svg>
                  <p>{{ $t("glo.pre.l1") }}</p>
                </div>
                <div  :key="i" class="glo-pre__item">
                  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
                    <path d="M13.5 24L24 34.5L40 18.5" stroke="white" stroke-width="3" stroke-linecap="round"/>
                    <circle cx="26" cy="26" r="24.5" stroke="white" stroke-width="3"/>
                  </svg>
                  <p>{{ $t("glo.pre.l2") }}</p>
                </div>
                <div  :key="i" class="glo-pre__item">
                  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
                    <path d="M13.5 24L24 34.5L40 18.5" stroke="white" stroke-width="3" stroke-linecap="round"/>
                    <circle cx="26" cy="26" r="24.5" stroke="white" stroke-width="3"/>
                  </svg>
                  <p>{{ $t("glo.pre.l3") }}</p>
                </div>
              </div>
            </div>
            <img src="/glo/pre.png" alt="" class="img desk">
            <img src="/glo/prem.png" alt="" class="img mob">
          </div>
          <div class="glo-pre__text desk">
            <p>{{ $t("glo.pre.text") }}</p>
          </div>
        </div>
        <div class="glo-pre__col">
            <h2>{{ $t("glo.pre.titleSecond") }}</h2>
            <div  :key="i" class="glo-pre__item">
              <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
                <path d="M38 38L13.9584 13.9584" stroke="white" stroke-width="3" stroke-linecap="round"/>
                <path d="M38 14L13.9584 38.0416" stroke="white" stroke-width="3" stroke-linecap="round"/>
                <circle cx="26" cy="26" r="24.5" stroke="white" stroke-width="3"/>
              </svg>
              <p>{{ $t("glo.pre.r1") }}</p>
            </div>
            <div  :key="i" class="glo-pre__item">
              <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
                <path d="M38 38L13.9584 13.9584" stroke="white" stroke-width="3" stroke-linecap="round"/>
                <path d="M38 14L13.9584 38.0416" stroke="white" stroke-width="3" stroke-linecap="round"/>
                <circle cx="26" cy="26" r="24.5" stroke="white" stroke-width="3"/>
              </svg>
              <p>{{ $t("glo.pre.r2") }}</p>
            </div>
            <div  :key="i" class="glo-pre__item">
              <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
                <path d="M38 38L13.9584 13.9584" stroke="white" stroke-width="3" stroke-linecap="round"/>
                <path d="M38 14L13.9584 38.0416" stroke="white" stroke-width="3" stroke-linecap="round"/>
                <circle cx="26" cy="26" r="24.5" stroke="white" stroke-width="3"/>
              </svg>
              <p>{{ $t("glo.pre.r3") }}</p>
            </div>
          </div>
          <div class="glo-pre__text mob">
            <p>*Данный продукт не является безопасным и содержит никотин, вещество, вызывающее привыкание. Сравнение дыма научной стандартной эталонной сигареты (приблизительно 9 мг смолы) и выбросов от glo™ по среднему значению 
                9 вредных компонентов, которые Всемирная организация здравоохранения рекомендует снизить в составе сигаретного дыма. Примечание: данное утверждение применимо к glo™ pro, glo™ hyper и glo™ hyper +.
                **По сравнению с обычной сигаретой при курении.
                Вышеуказанные качества не обязательно означают, что этот продукт менее вреден, чем другие табачные изделия.</p>
          </div>
      </div>
    </div>
    <div class="glo-teh">
      <div class="container">
        <div class="glo-teh__img">
          <h2 v-html="$t('glo.teh.title')"></h2>
          <img
            src="/glo/teh.png"
            alt=""
          />
        </div>
        <div class="glo-teh__info">
          <div class="glo-teh__graf">
            <div class="green">
              <div class="icon">250˚</div>
              <p>{{ $t("glo.teh.graf") }}</p>
            </div>
            <div class="red">
              <div class="icon">900˚</div>
              <p>{{ $t("glo.teh.grafSecond") }}</p>
            </div>
          </div>
          <div class="glo-teh__info-text">
            <h2>{{ $t("glo.teh.titleSecond") }}</h2>
            <p>{{ $t("glo.teh.text") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="glo-product">
      <div class="container">
        <h2 class="black">{{ $t("glo.productsList.title") }}</h2>
        <div class="glo-product__items">
          <div
            v-for="(product, i) in products.device"
            :key="i"
            class="glo-product__item"
          >
          <ProductSlider :items="product.img"/>
            <!-- <img
              src="/glo/p.png"
              alt=""
            /> -->
            <div class="title">
              {{ product.title }}
              <span v-if="product.slug">{{ product.slug }}</span>
            </div>
            <div>
              <p v-html="product.text"></p>
            </div>
            <span>{{ product.subtext }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="glo-product">
      <div class="container">
        <h2 class="black">{{ $t("glo.productsList.titleSecond") }}</h2>
        <div class="glo-product__stiks">
          <div class="glo-product__stiks-item" v-for="(stiks, i) in products.stiks" :key="i">
            <div class="glo-product__stiks-format">
              <img
                :src="`/glo/product/${stiks.img}`"
                alt=""
              />
              <div>
                <p>{{ stiks.title }}</p>
                <span>{{ stiks.text }}</span>
              </div>
            </div>
            <StikSlider :items="stiks.stiks"/>
            <!-- <div class="glo-product__item">
              
              <img
                src="/glo/p.png"
                alt=""
              />
              <div class="title">Exotic Blast</div>
              <p>С капсулой</p>
            </div> -->
          </div>

          <!-- <div>
            <div class="glo-product__stiks-format">
              <img
                src="/glo/sf2.png"
                alt=""
              />
              <div>
                <p>Деми формат</p>
                <span>для glo™ hyper pro и glo™ hyper Air</span>
              </div>
            </div>
            
            
            <div class="glo-product__item">
              <StikSlider />
              <img
                src="/glo/p.png"
                alt=""
              />
              <div class="title">Boost Red</div>
              <p>С капсулой</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="glo-slik">
      <div class="container">
        <p>
          {{ $t("glo.slik") }}
        </p>
        <img
          src="/glo/21.png"
          alt=""
        />
      </div>
    </div>
  </main>
</template>

<script>
// import axios from "@/plugins/axios"
// import {
//   getQuestionnaire,
//   questionnairesCheckUser,
//   questionnairesEndTest,
//   questionnaireResponse,
// } from "@/api/questionnaire"

export default {
  name: "DewarsPage",
  components: {
    ProductSlider: () => import("@/components/glo/ProductSlider.vue"),
    StikSlider: () => import("@/components/glo/StikSlider.vue")
  },
  data() {
    return {
      isImg: null,
      info: null,
      products: null,
      timer: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      isMob: false,
      isSlider: true,
      swiperOption: {
        spaceBetween: 85,
        slideTo: 2,
        initialSlide: 1,
        centeredSlides: true,

        breakpoints: {
          600: {
            slidesPerView: 2,
            spaceBetween: 85,
          },
          800: {
            slidesPerView: 3,
            spaceBetween: 85,
          },
          1000: {
            slidesPerView: 3,
          },
        },
      },
      productActive: null,
    }
  },
  computed: {
    swiper() {
      return this.$refs?.swiperRef
    },
  },
  created() {
    this.products = this.$t('glo.productsList')
    // axios.get(`/promos/83?populate[localizations]=%2A`).then((res) => {
    //   this.info = res.data.data
    //   if (this.info && res?.data?.data?.attributes?.localizations?.data?.length) {
    //     this.info.attributes.localizations = this.info.attributes?.localizations.data[0]
    //   }
    // })
  },
  mounted() {
    // this.Timer()
    // this.isMob = window.innerWidth < 500
    // window.addEventListener("resize", function () {
    //   this.isMob = window.innerWidth < 500
    // })
    // this.sl = "display: flex"
    // let routePath = this.$route.path.split("-")
    // if (routePath.length >= 2) {
    //   let id = this.slugs.find((e) => e.slug == routePath[1]).id
    //   getQuestionnaire(id).then((res) => {
    //     this.survey = res
    //   })
    // } else {
    //   getQuestionnaire(1).then((res) => {
    //     this.survey = res
    //   })
    // }
    // if (this.$route.query.q) {
    //   let q = this.$route.query.q.split(" ")
    //   this.user = {
    //     card: q[0],
    //     phone: q[1],
    //     test: 1,
    //   }
    //   questionnairesCheckUser(this.user).then((res) => {
    //     if (!res.isTest) {
    //       this.isShowPreview = false
    //       this.isShowSucces = true
    //     }
    //   })
    // } else {
    //   this.$router.push("/")
    // }
  },
  watch: {
    // progress: {
    //   handler() {
    //     this.error = false
    //     window.scrollTo(0, 0, "smooth")
    //   },
    // },
    // selected: {
    //   deep: true,
    //   handler() {
    //     this.error = false
    //   },
    // },
    // "selectedList.length": {
    //   handler() {
    //     this.error = false
    //   },
    // },
  },
  methods: {
    Timer() {
      var t = Date.parse(new Date("2024-12-31")) - Date.parse(new Date())
      this.timer.seconds = Math.floor((t / 1000) % 60)
      this.timer.minutes = Math.floor((t / 1000 / 60) % 60)
      this.timer.hours = Math.floor((t / (1000 * 60 * 60)) % 24)
      this.timer.days = Math.floor(t / (1000 * 60 * 60 * 24))
      setTimeout(() => this.Timer(), 1000)
    },
    getProduct() {
      console.log(this.$i18n.locale)

      let arr = []
      const prod = this.$i18n.locale === "ru" ? this.product : this.productkz
      for (let i = 0; i < 10; i++) {
        arr = [...arr, ...prod]
      }
      return arr
    },
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev()
    },
    rerender() {
      // if (this.isMob) {
      //   this.isSlider = false
      //   setTimeout(() => {
      //     this.isSlider = true
      //   }, 10)
      // }
    },
    nextSlide() {
      this.swiper.swiperInstance.slideNext()
    },
    // showQuestion() {
    //   this.isShowPreview = false
    //   this.isShowQuestion = true
    // },
    // showEnd() {
    //   this.isShowQuestion = false
    //   this.isShowEnd = true
    // },
    // showFalse() {
    //   this.isShowQuestion = false
    //   this.isShowFalse = true
    // },
    // nextPage() {
    //   if (this.isManyAnswers ? !this.selectedList.length : !this.selected) {
    //     this.error = true
    //     window.scrollTo(0, 0, "smooth")
    //     return
    //   }
    //   let questionnaire = {
    //     phone: this.user?.phone,
    //     card: this.user?.card,
    //     test_id: 1,
    //     question_id: this.survey.question[this.progress - 1].id,
    //   }
    //   if (this.isManyAnswers) {
    //     questionnaire.answers = this.selectedList.map((e) => e.label)
    //     questionnaireResponse(questionnaire)
    //     if (this.selectedList.length === 1 && this.selectedList.find((e) => e.label === "Сигареты")) {
    //       this.progress = this.selectedList.find((e) => e.label === "Сигареты")?.next_order
    //     } else {
    //       this.progress = this.selectedList.filter((e) => e.label !== "Сигареты")[0]?.next_order
    //     }
    //     this.selectedList = []
    //     this.isManyAnswers = false
    //   } else {
    //     questionnaire.answers = this.selected.label
    //     questionnaireResponse(questionnaire)
    //     if (this.selected?.end) {
    //       this.selected = null
    //       if (this.progress == 1 || this.progress == 2) {
    //         this.showFalse()
    //         return
    //       }
    //       this.showEnd()
    //       return
    //     }
    //     if (this.selected?.next_order) {
    //       if (this.survey.question.findIndex((e) => e.order_id === this.selected.next_order)) {
    //         this.progress =
    //           this.survey.question.findIndex((e) => e.order_id === this.selected.next_order) + 1
    //       }
    //       this.selected = null
    //       return
    //     }
    //     if (this.progress != 7) {
    //       this.progress++
    //     } else {
    //       this.showEnd()
    //     }
    //   }
    //   this.selected = null
    //   this.selectedList = []
    //   this.isManyAnswers = false
    // },
    // getImage(image) {
    //   const urlImg = image
    //   if (urlImg) {
    //     return this.$helpers.getAbsolutePath(urlImg)
    //   }
    // },
    // getActive(item) {
    //   if (this.isManyAnswers) {
    //     return this.selectedList.findIndex((e) => e.id === item.id) != -1 ? true : false
    //   } else {
    //     return this.selected ? item.id == this.selected.id : false
    //   }
    // },
    // selectedItem(survey, item) {
    //   if (survey.mani_answers) {
    //     this.isManyAnswers = true
    //     // if (
    //     //   this.selectedList.length &&
    //     //   this.selectedList.find((e) => e.next_order !== item.next_order)
    //     // ) {
    //     //   this.selectedList = []
    //     // }
    //     if (this.selectedList.findIndex((e) => e.id === item.id) == -1) {
    //       this.selectedList.push(item)
    //     } else {
    //       this.selectedList.splice(
    //         this.selectedList.findIndex((e) => e.id === item.id),
    //         1,
    //       )
    //     }
    //     return
    //   }
    //   if (this.selected?.id === item.id) {
    //     this.selected = null
    //   } else {
    //     this.selected = item
    //   }
    // },
    // finishedSurvey() {
    //   questionnairesEndTest(this.user).then((res) => {
    //     if (res.end) {
    //       window.location.href = this.survey.link
    //       return
    //     }
    //     if (res.error) {
    //       alert(res.error)
    //     }
    //   })
    // },
  },
}
</script>

<style
  lang="scss"
  scoped
></style>
